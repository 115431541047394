<template>
  <div class="py-5 text-center text-md-start">
    <h1 class="title text-center text-capitalize">
      <div v-if="this.$store.getters.lan">Company tax return</div>
      <div v-else>公司税务申报</div>
    </h1>
    <div class="title fs-5">
      <div v-if="this.$store.getters.lan">
        <p>
          As an incorporation company, the tax filing time is related to the
          fiscal year selected by the company.
        </p>
        <p>
          The tax filing process must at least specify its pre-tax income and
          some special deductions. For example, if the company has made losses
          in the past and has reported to the CRA, the previous losses can be
          used to offset income. The loss is valid for up to 10 years. In the
          first three years, it can not only offset the tax income of the coming
          year, but also offset the tax income of the three years before the
          year the loss occurred. If the company has dividend income, and the
          dividend comes from a Canadian company and has paid income tax, then
          these dividends can be deducted from the tax income.
        </p>
        <p>
          YANG GAO. CPA P.C Ltd. has been focusing on learning the updates and
          changes of Canadian tax law all year round. We will provide customers
          with more secure tax declaration and services and striving for the
          greatest tax planning effect.
        </p>
      </div>
      <div v-else>
        <p>
          有限公司的报税时间与公司选择的财政年度有关，可以由公司决定报税时间。有限公司的报税至少要明确税前收入，以及一些特殊的抵扣额度。比如，公司以往有亏损，而且已向税局申报过，那以往的亏损可以用来抵冲收入。亏损有效期最多为10年。前三年，不仅可抵冲来年的纳税收入，还能抵冲亏损发生那年以前三年内的税收入。如果公司有红利收入，且红利来自加拿大公司，并已缴纳过所得税，那么这些红利可以从纳税收入中扣除。
          华杨会计师事务所常年专注于学习加拿大税法的更新与变化，给客户提供更有保障的税务申报与服务，争取最大的税务筹划效果。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>